import { Module, Mutation, VuexModule } from 'vuex-module-decorators'
import dayjs from 'dayjs'

import {
  IPackageCompareQuery
} from 'modules/product-search.v2/data/product-search.types'

import {
  formatDateToYMD
} from 'common/filters/formattedDates'

import {
  VacationTypes
} from 'applications/desktop/package-compare-app/types/package-compare-app.types'

export const  PRODUCT_SEARCH_VIEW_NAME = 'PRODUCT_SEARCH_VIEW_MODULE'

export const PRODUCT_SEARCH_VIEW = () => {
  @Module({ name: PRODUCT_SEARCH_VIEW_NAME, namespaced: true })
  class ProductSearchModuleView extends VuexModule {
    private _searchWithSpecificCountry: boolean = false
    private _allVacationTypes = Object.keys(VacationTypes)
    private _vacationTermBlank: IPackageCompareQuery = {
      vacationTypes: ['BEACH', 'FAMILY', 'CITY'],
      dateInterval: {
        start: formatDateToYMD(dayjs().add(7, 'days').toDate()),
        end: formatDateToYMD(dayjs().add(14, 'days').toDate()),
      },
      nights: { min: 1, max: 20 },
      paxes: {
        'ADULT': 2,
        'CHILD': 0,
        'INFANT': 0
      },
      exactDates: true  // TODO make false after BE fix range dates
    }


    get searchWithSpecificCountry() {
      return this._searchWithSpecificCountry
    }

    get vacationTermBlank() {
      return this._vacationTermBlank
    }

    get allVacationTypes() {
      return this._allVacationTypes
    }


    @Mutation
    setSearchWithSpecificCountry(value: boolean) {
      this._searchWithSpecificCountry = value
    }

    @Mutation
    setVacationTermBlack(vacationTermBlank: IPackageCompareQuery) {
      this._vacationTermBlank = vacationTermBlank
    }

    @Mutation
    setAllVacationTypesBlank(allVacationTypes: IPackageCompareQuery['vacationTypes']) {
      this._allVacationTypes = allVacationTypes
    }
  }

  return ProductSearchModuleView
} 
