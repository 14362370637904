import {
  IPaxComplect
} from 'modules/product-result.v2/data/product-result.types';

import { PackageProductPrice } from 'modules/product-result.v2/data/package-result/package-result.product-price/package-result.product-price.class'
import {PaxComplectResource} from "be-structures/typescript-generator";

export const PAX_COMPLECT_BLANK: PaxComplectResource = {
  createTs: undefined,
  createdBy: "",
  deleteTs: undefined,
  deletedBy: "",
  id: undefined,
  links: [],
  paxAges: undefined,
  price: new PackageProductPrice(),
  sourceCurrency: "",
  updateTs: undefined,
  updatedBy: "",
  entityId: null,
  roomGroupKey: null,
  roomType: null,
  bookingCode: null,
  remark: null
}